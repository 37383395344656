<script>
import qs from 'query-string'
import DatePicker from "@vuepic/vue-datepicker"
import axios from 'axios'

export default {
  components: {
    DatePicker,
  },
  props: {
    apiUrl: String,
    response: String,
    data: Object,
    requireVendors: Boolean,
    requireIpAddress: Boolean,
    requireRequestKey: Boolean,
    requirePlay: Boolean
  },
  data() {
    return {
      header: {
        accept: 'application/json',
        contentType: 'application/x-www-form-urlencoded',
        'k-secret': this.$store.getters['auth/user'].apiKey,
        'k-username': this.$store.getters['auth/user'].username
      },
      body: null,
      ipAddress: '127.0.0.1',
      requestKey: new Date().getTime(),
      returnData: '',
      vendors: [],
      games: [],
      openDialog: false,
      selectedVendor: null,
      playUrl: '',
      vendorsLoading: false
    }
  },
  async mounted() {
    this.body = this.data
    this.returnData = this.$t('text.api.run_desc')

    // 사용자 아이피 확인
    if (this.requireIpAddress === true) {
      try {
        const result = await axios.get('https://api.ipify.org?format=json')
        this.ipAddress = result.data.ip
      } catch(e) {
        this.$log.warn(e)
        this.ipAddress = '127.0.0.1'
      }
    }

    // 게임사 필요 여부
    if (this.requireVendors === true) {
      this.getVendors().then()
    }
  },
  methods: {
    async run() {
      const loader = this.$loading.show({
        container: this.$refs.conditionContainer,
        canCancel: false
      })
      try {
        this.playUrl = ''

        const postData = this.getData()
        if (this.requireIpAddress) {
          postData.ipAddress = this.ipAddress
        }

        if (this.requireRequestKey) {
          postData.requestKey = this.requestKey
        }

        const result = await axios.post(this.apiUrl, postData, { headers: this.header })
        this.returnData = JSON.stringify(result.data, null, ' ')
        if (result.data.url) {
          this.playUrl = result.data.url
        }
      } catch (e) {
        this.$log.error(e)
        alert(this.$t('text.api_request_error'))
      } finally {
        this.requestKey = new Date().getTime()
        loader.hide()
      }
    },
    getData() {
      const source = {}
      for(const key in this.body) {
        source[key] = this.body[key].value
      }

      if (this.requireIpAddress) {
        source.ipAddress = this.ipAddress
      }

      if (this.requireRequestKey) {
        source.requestKey = this.requestKey
      }

      return source
    },
    // 게임사 목록
    async getVendors() {
      try {
        this.vendorsLoading = true
        const host = process.env.VUE_APP_API_SERVER_URL
        /*
        let host = location.host
        if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
          host = 'http://127.0.0.1:39011/'
        } else {
          host = `https://api.${host}/`
        }
         */
        const apiUrl = host

        const result = await axios.post(apiUrl + 'vendors', {}, { headers: this.header })
        if (result.data.code !== 0) {
          return alert(result.data.msg)
        }
        this.vendors = result.data.vendors

      } catch (e) {
        this.$log.error(e)
      } finally {
        this.vendorsLoading = false
      }
    },

    playGame() {
      if (this.playUrl) {
        window.open(this.playUrl)
      }
    },

    findVendor (key) {
      let result = null
      for(const idx in this.vendors) {
        const vendor = this.vendors[idx]
        if (vendor.key === key) {
          result = vendor
          break
        }
      }

      return result
    },

    // 게임사 변경에 따른 게임 목록
    async changeVendor(key) {
      // console.log('*****', key, this.body[key].value)
      this.games = []
      // this.body.gameKey.value = ''
      if (!this.body[key].value) return

      const loader = this.$loading.show({
        container: this.$refs.conditionContainer,
        canCancel: false
      })
      try {
        const vendorKey = this.body[key].value
        this.selectedVendor = this.findVendor(vendorKey)
        if (!this.selectedVendor) {
          alert(this.$t('text.error_not_selected_vendor'))
          return
        }

        const params = {vendorKey}
        params.useLobby = this.selectedVendor.useLobby

        const host = process.env.VUE_APP_API_SERVER_URL
        /*
        let host = location.host
        if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
          host = 'http://127.0.0.1:39011/'
        } else {
          host = `https://api.${host}/`
        }
         */
        const apiUrl = host

        const result = await axios.post(apiUrl + 'games', params, { headers: this.header })
        if (result.data.code !== 0) {
          return alert(result.data.msg)
        }
        this.games = result.data.games
        this.openGamesDialog()

      } catch (e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },

    // 게임 모달창 열기
    openGamesDialog () {
      if (this.selectedVendor.useLobby === 1) {
        // this.body.gameKey.value = 'lobby'
        this.body.gameKey.value = ''
        return
      }
      this.openDialog = true
    },

    setGameKey(key) {
      this.body.gameKey.value = key
      this.openDialog = false
    }
  },
  computed: {
    phpSource: function () {
      const source = this.getData()
      return qs.stringify(source)
    },
    javaSource: function () {
      const source = this.getData()
      return qs.stringify(source)
    },
    nodeSource: function () {
      const source = this.getData()
      return JSON.stringify(source, null, ' ')
    },
    aspSource: function() {
      const source = this.getData()
      return qs.stringify(source)
    }
  }
};
</script>

<style scoped>
  .tab-page pre code {
    min-height: 300px;
  }

  .tab-response pre code {
    min-height: 200px;
  }

  .mouseover {

  }

  .mouseover:hover {
    background-color: #F0F0F0;
  }
</style>

<template>
  <div class="row mt-3 mb-0">
    <div class="col-md-4" ref="conditionContainer">
      <form @submit.prevent="run">
        <b-tabs content-class="pt-3 text-muted active">
          <b-tab active class="border-0">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">{{ $t('API테스트') }}</span>
              <span class="d-none d-sm-inline-block">{{ $t('API테스트') }}</span>
            </template>

            <template v-for="(value, key) in body" :key="'document-body-field-' + key">
              <div class="mb-1 text-end">
                <span class="badge bg-primary border border-primary me-1" v-if="value.required === true">{{ $t('필수') }}</span>
                <span
                    class="badge bg-success border border-success me-1" v-if="value.length">{{ value.length }}{{ $t('글자')}}</span>
              </div>

              <!-- 텍스트 데이터 //-->
              <div class="form-floating mb-3" v-if="value.type === 'text'" >
                <input type="text" class="form-control" :placeholder="key + ' : ' + value.desc" v-model="body[key].value"/>
                <label class="form-label">{{ key + ' : ' + value.desc }}</label>
              </div>

              <!-- 숫자 //-->
              <div class="form-floating mb-3" v-if="value.type === 'number'" >
                <input type="number" min="0" class="form-control" :placeholder="key + ' : ' + value.desc" v-model="body[key].value"/>
                <label class="form-label">{{ key + ' : ' + value.desc }}</label>
              </div>

              <!-- JSON 데이터 //-->
              <div class="form-floating mb-3" v-if="value.type === 'json'" >
                <textarea class="form-control" style="height: 300px;" rows="10" :placeholder="key + ' : ' + value.desc" v-model="body[key].value" :readonly="body[key].readonly === true"></textarea>
                <label class="form-label">{{ key + ' : ' + value.desc }}</label>
              </div>

              <!-- 날짜 데이터 //-->
              <template v-if="value.type === 'date'">
                <div class="form-group mb-3">
                  <DatePicker
                      v-model="body[key].value"
                      :format="'yyyy-MM-dd HH:mm:ss'"
                      :placeholder="value.desc"
                      v-if="value.type === 'date'"
                      utc
                      auto-apply
                      :close-on-auto-apply="false"
                  ></DatePicker>
                </div>
                <div class="form-floating mb-3" >
                  <input type="text" class="form-control" :placeholder="key + ' : ' + value.desc" v-model="body[key].value"/>
                  <label class="form-label">{{ key + ' : ' + value.desc }}</label>
                </div>
              </template>

              <!-- 리스트 //-->
              <div class="form-floating mb-3" v-if="value.type === 'list'">
                <select class="form-control form-select" :placeholder="key + ' : ' + value.desc" v-model="body[key].value">
                  <template v-for="record in body[key].list" :key="'doc-key-' + record">
                    <option :value="record">{{ record }}</option>
                  </template>
                </select>
                <label class="form-label">{{ key + ' : ' + value.desc }}</label>
              </div>

              <!-- 게임사 목록 //-->
              <div class="form-floating mb-3" v-if="value.type === 'vendors'">
                <select class="form-control form-select" :placeholder="'vendorKey : '  + $t('text.api.vendorKey_desc')" v-model="body[key].value" @change="changeVendor(key)">
                  <option value="" selected>::{{ $t('게임사 선택') }}::</option>
                  <template v-for="record in vendors" :key="'vendor-key-' + record.key">
                    <option :value="record.key">{{ record.names[$store.getters['auth/user'].language] }} - {{ record.key }}</option>
                  </template>
                </select>
                <label class="form-label">vendorKey : {{ $t('text.api.vendorKey_desc') }}</label>
              </div>

              <!-- 게임사 목록 단순 리스트 //-->
              <div class="form-floating mb-3" v-if="value.type === 'onlyVendors'">
                <select class="form-control form-select" :placeholder="'vendorKey : '  + $t('text.api.vendorKey_desc')" v-model="body[key].value">
                  <option value="" selected>::{{ $t('게임사 선택') }}::</option>
                  <template v-for="record in vendors" :key="'vendor-key-' + record.key">
                    <option :value="record.key">{{ record.names[$store.getters['auth/user'].language] }} - {{ record.key }}</option>
                  </template>
                </select>
                <label class="form-label">vendorKey : {{ $t('text.api.vendorKey_desc') }}</label>
              </div>

              <!-- 게임 목록 //-->
              <template v-if="value.type === 'games'">
                <div class="form-floating mb-3" v-if="selectedVendor && selectedVendor.useLobby === 1">
                  <select class="form-control form-select" :placeholder="'게임 선택'" v-model="body[key].value">
                    <option value="" selected>::{{ $t('게임 선택') }}::</option>
                    <template v-for="record in games" :key="'game-key-' + record.key">
                      <option :value="record.key">{{ record.names[$store.getters['auth/user'].language] }} - {{ record.key }}</option>
                    </template>
                  </select>
                  <label class="form-label">gameKey : {{ $t('text.api.gameKey_desc') }}</label>
                </div>

                <div class="form-floating mb-3" v-if="selectedVendor && selectedVendor.useLobby === 0">
                  <input type="text" class="form-control" :placeholder="key + ' : ' + value.desc" v-model="body[key].value" :disabled="games.length === 0" />
                  <label class="form-label">{{ key + ' : ' + value.desc }}</label>
                </div>
                <button type="button" class="btn btn-sm btn-success w-100 mb-3" @click="openGamesDialog()" :disabled="games.length === 0" v-if="selectedVendor && selectedVendor.useLobby !== 1"><span class="fa fa-gamepad"></span> {{ $t('게임 목록 보기') }}</button>
              </template>
            </template>

            <!-- IP //-->
            <template v-if="requireIpAddress === true">
              <div class="mb-1 text-end">
                <span class="badge bg-primary border border-primary me-1">{{ $t('필수') }}</span>
              </div>

              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="'ipAddress : '  + $t('text.api.ipAddress_desc')" v-model="ipAddress"/>
                <label class="form-label">ipAddress : {{ $t('text.api.ipAddress_desc') }}</label>
              </div>
            </template>

            <!-- Request Key //-->
            <template v-if="requireRequestKey === true">
              <div class="mb-1 text-end">
                <span class="badge bg-primary border border-primary me-1">{{ $t('필수') }}</span>
              </div>

              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="'IP'" v-model="requestKey"/>
                <label class="form-label">requestKey : {{ $t('text.api.requestKey_desc') }}</label>
              </div>
            </template>

            <div>
              <button type="submit" class="btn btn-warning w-100"><span class="fa fa-running"></span> {{ $t('실행') }}</button>
            </div>
          </b-tab>
        </b-tabs>
      </form>
    </div>
    <div class="col-md-8 tab-page">
      <b-tabs content-class="pt-3 text-muted" v-if="body">
        <b-tab active class="border-0">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">{{ $t('응답설명') }}</span>
            <span class="d-none d-sm-inline-block">{{ $t('응답설명') }}</span>
          </template>
          <pre v-highlightjs class="mb-0">
            <code class="javascript ps-4 pe-4 rounded rounded-2">
{{ response }}
            </code>
          </pre>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">PHP</span>
            <span class="d-none d-sm-inline-block">PHP</span>
          </template>
          <pre class="rounded-lg" v-highlightjs><code class="php ps-4 pe-4 rounded rounded-2">
&lt;?php
  $curl = curl_init();
  curl_setopt_array($curl, array(
    CURLOPT_URL => '{{ apiUrl }}',
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => 'POST',
    CURLOPT_POSTFIELDS => '{{ phpSource }}',
    CURLOPT_HTTPHEADER => array(
      'k-username: {{ $store.getters['auth/user'].username }}',
      'k-secret: {{ $store.getters['auth/user'].apiKey }}',
      'User-agent: Mozilla',
      'Content-Type: application/x-www-form-urlencoded'
    )
  ));
  $response = curl_exec($curl);
  curl_close($curl);
  echo $response;
          </code></pre>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">ASP</span>
            <span class="d-none d-sm-inline-block">ASP</span>
          </template>
          <pre class="rounded-lg" v-highlightjs><code class="asp ps-4 pe-4 rounded rounded-2">
strjson = "{{ aspSource }}"

set req = Server.CreateObject("MSXML2.ServerXMLHTTP")
req.open "POST", "{{ apiUrl }}", False
req.setRequestHeader "accept", "application/json"
req.setRequestHeader "Content-Type", "application/x-www-form-urlencoded"
req.setRequestHeader "k-secret", "{{ $store.getters['auth/user'].apiKey }}"
req.setRequestHeader "k-username", "{{ $store.getters['auth/user'].username }}"
req.send strjson

response.Write R_Msg
          </code></pre>
        </b-tab>

      </b-tabs>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12 tab-response">
      <b-tabs content-class="pt-3 text-muted active">
        <b-tab active class="border-0">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">{{ $t('수신 데이터') }}</span>
            <span class="d-none d-sm-inline-block">{{ $t('수신 데이터') }}</span>
          </template>
          <pre class="rounded" v-highlightjs>
            <code class="language-javascript ps-4 pe-4 rounded rounded-2" style="max-height: 500px; overflow-y: auto">
    {{ returnData }}
            </code>
          </pre>
          <div class="text-center" v-if="requirePlay === true">
            <button type="button" class="btn btn-primary" @click="playGame()"><span class="fa fa-gamepad"></span> {{ $t('게임 실행') }}</button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>

  <b-modal
      :title="$t('게임 목록')"
      v-model="openDialog"
      scrollable
      centered
      size="xl"
      button-size="sm"
      hide-footer
  >
    <div class="row">
      <div class="col-md-2" v-for="game in games" :key="game._id">
        <div class="text-center border border-1 shadow shadow-sm p-2 mb-4 mouseover" style="height: 220px;cursor: pointer;" @click="setGameKey(game.key)">
          <div style="display: inline-block; height: 150px; width: 150px; background-size: contain; background-repeat: no-repeat; background-position-y: center;" :style="{'background-image': 'url(' + game.image + ')'}"></div>
          <div>{{ game.names[$store.getters['auth/user'].language] }}</div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
